var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "the-toolbar",
        { attrs: { title: "Envio de Boletos" } },
        [
          _c(
            "v-btn",
            {
              attrs: { "data-test-id": "atualizar", icon: "" },
              on: { click: _vm.handleReload },
            },
            [_c("v-icon", [_vm._v("mdi-reload")])],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c(
            "v-row",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("base-text-field", {
                    attrs: {
                      id: "pesquisa",
                      name: "pesquisa",
                      value: _vm.pesquisa,
                      label: "Pesquisar por Nome ou Nº Proposta",
                      "icon-append": "mdi-magnify",
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                    },
                    on: { input: _vm.debounceInput },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _c(
            "v-container",
            { staticClass: "pa-5 pt-10" },
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-3",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "p",
                    { staticClass: "text-right ma-0 subtitle-2 grey--text" },
                    [
                      _vm._v(
                        _vm._s(_vm.propostas.length) +
                          " registro(s) encontrados(s)"
                      ),
                    ]
                  ),
                ]
              ),
              _vm.propostas.length
                ? _c(
                    "v-row",
                    _vm._l(_vm.propostas, function (proposta, index) {
                      return _c(
                        "v-col",
                        {
                          key: proposta.id,
                          attrs: { cols: "12", md: "6", xl: "4" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("base-card-details-proposta", {
                                attrs: {
                                  index: index,
                                  numero: proposta.nrProposta || "--",
                                  nome: proposta.titular
                                    ? proposta.titular.nome
                                    : "",
                                  operadora:
                                    proposta.plano.nomeExibicaoOperadora ||
                                    (proposta.plano &&
                                      proposta.plano.operadora),
                                  entidade:
                                    proposta.titular &&
                                    proposta.titular.entidade,
                                  status: proposta.etapa.titulo,
                                  etapa: proposta.etapa || {},
                                  tempoParaTransmissao: proposta.vigencia || {},
                                  permitidoTransmissao: null,
                                  valor: Number(proposta.valorTotalProposta),
                                  valorTotalOdontoAdicional:
                                    proposta.plano.valorTotalOdontoAdicional,
                                  envioBoleto: true,
                                  corretora: proposta.corretora || "--",
                                  vigencia: proposta.vigencia || {},
                                  formaPagamento:
                                    proposta.formaPagamento || "--",
                                },
                                on: {
                                  onClickEnviarBoleto: _vm.onClickEnviarBoleto,
                                  isLoading: _vm.isLoading,
                                  setBoletos: _vm.setBoletos,
                                  onClickCompartilhar: _vm.onClickCompartilhar,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showModalEnviarBoleto,
            callback: function ($$v) {
              _vm.showModalEnviarBoleto = $$v
            },
            expression: "showModalEnviarBoleto",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Enviar boleto ")]),
              _vm.propostaSelecionada.formaPagamento === "Boleto" ||
              (_vm.propostaSelecionada.formaPagamento !== "Boleto" &&
                _vm.propostaSelecionada.boletos.length)
                ? _c(
                    "v-card-text",
                    { staticClass: "py-3" },
                    [
                      _vm.propostaSelecionada.boletos.length
                        ? _vm._l(
                            _vm.propostaSelecionada.boletos,
                            function (boleto, index) {
                              return _c(
                                "div",
                                { key: "boleto" + index },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { "align-self": "center" } },
                                        [
                                          _c("p", { staticClass: "my-3" }, [
                                            _vm._v(
                                              " Boleto (" +
                                                _vm._s(
                                                  _vm.formatRefData(
                                                    boleto.formatRef
                                                  )
                                                ) +
                                                "): " +
                                                _vm._s(
                                                  boleto
                                                    ? _vm.checkStatusBoleto(
                                                        boleto
                                                      )
                                                    : "Não emitido."
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: {
                                            cols: "4",
                                            "align-self": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm
                                                    .checkStatusBoleto(boleto)
                                                    .substr(0, 1) !== "V",
                                                color: "warning",
                                                "x-small": "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onClickCompartilhar(
                                                    boleto
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Compartilhar ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                      _vm.propostaSelecionada.boletosNaoEmitidos.length
                        ? _vm._l(
                            _vm.propostaSelecionada.boletosNaoEmitidos,
                            function (boletoNaoEmitido, index) {
                              return _c(
                                "div",
                                { key: "boletoNaoEmitido" + index },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { "align-self": "center" } },
                                        [
                                          _c("p", { staticClass: "my-3" }, [
                                            _vm._v(
                                              "Boleto: " +
                                                _vm._s(boletoNaoEmitido.status)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: {
                                            cols: "4",
                                            "align-self": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                disabled: true,
                                                color: "warning",
                                                "x-small": "",
                                              },
                                            },
                                            [_vm._v(" Compartilhar ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _c("v-card-text", [
                    _vm._v(" Boletos indisponíveis para esta proposta. "),
                  ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showModalEnviarBoleto = false
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showModalCompartilhar,
            callback: function ($$v) {
              _vm.showModalCompartilhar = $$v
            },
            expression: "showModalCompartilhar",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Compartilhar ")]),
              _c(
                "v-card-text",
                [
                  _vm._v(
                    " Escolha o método que deseja compartilhar o seu boleto: "
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.enviarBoleto("email", _vm.boleto)
                                },
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("mdi-email-arrow-right-outline"),
                              ]),
                              _c("span", { staticClass: "mt-1" }, [
                                _vm._v(" E-mail "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.boleto.flagSAP
                        ? _c(
                            "v-col",
                            { staticClass: "d-flex justify-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "green" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.enviarBoleto(
                                        "whatsapp",
                                        _vm.boleto
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "#FFFFFF" },
                                    },
                                    [_vm._v("mdi-whatsapp")]
                                  ),
                                  _c("span", { staticClass: "whatsapp mt-1" }, [
                                    _vm._v(" Whatsapp "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showModalCompartilhar = false
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "250" },
          model: {
            value: _vm.loadingSelected,
            callback: function ($$v) {
              _vm.loadingSelected = $$v
            },
            expression: "loadingSelected",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "justify-center" }, [
                _vm._v(" Carregando "),
              ]),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center pa-5" },
                  [
                    _c("v-progress-circular", {
                      attrs: { color: "primary", indeterminate: "" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }