<template>
  <div>
    <the-toolbar title="Envio de Boletos">
      <v-btn :data-test-id="'atualizar'" icon @click="handleReload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </the-toolbar>
    <div class="mt-10">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <base-text-field id="pesquisa" name="pesquisa" :value="pesquisa" label="Pesquisar por Nome ou Nº Proposta" icon-append="mdi-magnify" clearable outlined hide-details @input="debounceInput" />
        </v-col>
      </v-row>
    </div>
    <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <v-container class="pa-5 pt-10" v-else>
      <v-row align="center" justify="center" class="mb-3">
        <p class="text-right ma-0 subtitle-2 grey--text">{{ propostas.length }} registro(s) encontrados(s)</p>
      </v-row>
      <v-row v-if="propostas.length">
        <v-col cols="12" md="6" xl="4" v-for="(proposta, index) in propostas" :key="proposta.id">
          <div>
            <base-card-details-proposta
              :index="index"
              :numero="proposta.nrProposta || '--'"
              :nome="proposta.titular ? proposta.titular.nome : ''"
              :operadora="proposta.plano.nomeExibicaoOperadora || (proposta.plano && proposta.plano.operadora)"
              :entidade="proposta.titular && proposta.titular.entidade"
              :status="proposta.etapa.titulo"
              :etapa="proposta.etapa || {}"
              :tempoParaTransmissao="proposta.vigencia || {}"
              :permitidoTransmissao="null"
              :valor="Number(proposta.valorTotalProposta)"
              :valorTotalOdontoAdicional="proposta.plano.valorTotalOdontoAdicional"
              :envioBoleto="true"
              :corretora="proposta.corretora || '--'"
              :vigencia="proposta.vigencia || {}"
              :formaPagamento="proposta.formaPagamento || '--'"
              @onClickEnviarBoleto="onClickEnviarBoleto"
              @isLoading="isLoading"
              @setBoletos="setBoletos"
              @onClickCompartilhar="onClickCompartilhar"
            ></base-card-details-proposta>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showModalEnviarBoleto" max-width="500">
      <v-card>
        <v-card-title>
          Enviar boleto
        </v-card-title>
        <v-card-text v-if="propostaSelecionada.formaPagamento === 'Boleto' || (propostaSelecionada.formaPagamento !== 'Boleto' && propostaSelecionada.boletos.length)" class="py-3">
          <template v-if="propostaSelecionada.boletos.length">
            <div v-for="(boleto, index) in propostaSelecionada.boletos" :key="'boleto' + index">
              <v-row>
                <v-col align-self="center">
                  <p class="my-3">
                    Boleto ({{ formatRefData(boleto.formatRef) }}):
                    {{ boleto ? checkStatusBoleto(boleto) : "Não emitido." }}
                  </p>
                </v-col>
                <v-col cols="4" align-self="center" class="text-right">
                  <v-btn :disabled="checkStatusBoleto(boleto).substr(0, 1) !== 'V'" color="warning" x-small @click="onClickCompartilhar(boleto)">
                    Compartilhar
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-if="propostaSelecionada.boletosNaoEmitidos.length">
            <div v-for="(boletoNaoEmitido, index) in propostaSelecionada.boletosNaoEmitidos" :key="'boletoNaoEmitido' + index">
              <v-row>
                <v-col align-self="center">
                  <p class="my-3">Boleto: {{ boletoNaoEmitido.status }}</p>
                </v-col>
                <v-col cols="4" align-self="center" class="text-right">
                  <v-btn :disabled="true" color="warning" x-small>
                    Compartilhar
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-card-text>
        <v-card-text v-else>
          Boletos indisponíveis para esta proposta.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="showModalEnviarBoleto = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showModalCompartilhar" max-width="500">
      <v-card>
        <v-card-title>
          Compartilhar
        </v-card-title>
        <v-card-text>
          Escolha o método que deseja compartilhar o seu boleto:
          <v-row class="mt-5">
            <v-col class="d-flex justify-center">
              <v-btn color="primary" @click="enviarBoleto('email', boleto)">
                <v-icon class="mr-2">mdi-email-arrow-right-outline</v-icon>
                <span class="mt-1">
                  E-mail
                </span>
              </v-btn>
            </v-col>
            <v-col v-if="!boleto.flagSAP" class="d-flex justify-center">
              <v-btn color="green" @click="enviarBoleto('whatsapp', boleto)">
                <v-icon color="#FFFFFF" class="mr-2">mdi-whatsapp</v-icon>
                <span class="whatsapp mt-1">
                  Whatsapp
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="showModalCompartilhar = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadingSelected" persistent max-width="250">
      <v-card>
        <v-card-title class="justify-center">
          Carregando
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-center pa-5">
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import propostaService from "@/services/propostaService";
import envioBoletoService from "@/services/envioBoletoService";
import BaseCardDetailsProposta from "@/components/BaseCardDetailsProposta.vue";
import TheToolbar from "@/components/TheToolbar.vue";
import BaseTextField from "@/components/BaseTextField.vue";
import { debounce, findIndex } from "lodash";
import moment from "moment";

export default {
  name: "Propostas",
  components: { TheToolbar, BaseCardDetailsProposta, BaseTextField },
  data() {
    return {
      loading: false,
      loadingSelected: false,
      propostas: [],
      propostaSelecionada: {
        formaPagamento: null,
        boletos: [],
        boletosNaoEmitidos: [],
      },
      pesquisa: "",
      showModalEnviarBoleto: false,
      showModalCompartilhar: false,
      boleto: {},
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      user: (state) => state.user.profile,
    }),
  },
  methods: {
    formatRefData(data) {
      return moment(data).format("MM/YYYY");
    },
    async getData(filtros = {}) {
      try {
        this.loading = true;
        const data = await propostaService.lista({
          filtros,
          statusAgrupado: "envio_boleto",
          cpf: this.user.cpf,
        });
        this.propostas = this.orderByChangeDate(data);
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao buscar dados, tente novamente mais tarde.",
        });
      } finally {
        this.loading = false;
      }
    },
    checkStatusBoleto(boleto) {
      let status;
      if (boleto.dataPagamento) status = `PAGO EM ${boleto.dataPagamento.substr(0, 10)}.`;
      else if (boleto.dataVencimento) status = `Vencimento em ${boleto.dataVencimento.substr(0, 10)}.`;
      else status = "Não emitido.";
      return status;
    },
    /* eslint-disable */
    debounceInput: debounce(async function(elem) {
      this.pesquisa = elem;
      const obj = elem ? { nomeNrProposta: elem } : {};
      await this.getData(obj);
    }, 1000),
    /* eslint-enable */
    async handleReload() {
      const obj = this.pesquisa ? { nomeNrProposta: this.pesquisa } : {};
      await this.getData(obj);
    },
    orderByChangeDate(array) {
      array.sort((a, b) => new Date(b.dataAlteracao) - new Date(a.dataAlteracao));
      return array;
    },
    onClickEnviarBoleto() {
      this.showModalEnviarBoleto = true;
    },
    isLoading(flag) {
      this.loadingSelected = flag;
    },
    setBoletos(nrProposta, boletos, boletosNaoEmitidos, formaPagamento) {
      const index = findIndex(this.propostas, { nrProposta });
      this.propostaSelecionada = {
        ...this.propostas[index],
        formaPagamento,
        boletos,
        boletosNaoEmitidos,
      };
    },
    onClickCompartilhar(boleto) {
      this.boleto = boleto;
      this.showModalCompartilhar = true;
    },
    async enviarBoleto(tipo, boleto) {
      try {
        this.loadingSelected = true;
        if (tipo === "email") {
          const { flagSAP } = boleto;
          flagSAP ? await envioBoletoService.enviarBoletoEmailSAP(boleto) : await envioBoletoService.enviarBoletoEmail(boleto);
        } else if (tipo === "whatsapp") await envioBoletoService.enviarBoletoWhatsapp(boleto);
        this.$root.$snackBar.open({
          color: "success",
          message: "Boleto enviado com sucesso.",
        });
      } catch (error) {
        this.$root.$snackBar.open({
          color: "error",
          message: "Erro ao enviar boleto, tente novamente mais tarde.",
        });
      } finally {
        this.loadingSelected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp {
  color: #ffffff;
}
</style>
